import axios from "axios"
const setAuthToken = (token) => {
	// console.log(token)
	// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
	if (token) {
		// Apply to every request
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
	} else {
		// Remove token from request
		delete axios.defaults.headers.common["Authorization"]
		axios.defaults.headers.common["Authorization"] = null
	}
}

export default setAuthToken
