import {
  GET_GROUPS,
  GET_GROUPS_FAIL,
  GET_GROUPS_SUCCESS,
  GET_GROUP_DETAIL,
  GET_GROUP_DETAIL_FAIL,
  GET_GROUP_DETAIL_SUCCESS,
  ADD_NEW_GROUP,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAIL,
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  CLEAR_GROUP_NOTIFICATION,
} from "./actionTypes"

export const getGroups = () => ({
  type: GET_GROUPS,
})

export const getGroupsSuccess = groups => ({
  type: GET_GROUPS_SUCCESS,
  payload: groups,
})

export const getGroupsFail = error => ({
  type: GET_GROUPS_FAIL,
  payload: error,
})

export const addNewGroup = groups => ({
  type: ADD_NEW_GROUP,
  payload: groups,
})

export const addGroupSuccess = groups => ({
  type: ADD_GROUP_SUCCESS,
  payload: groups,
})

export const addGroupFail = error => ({
  type: ADD_GROUP_FAIL,
  payload: error,
})

export const getGroupDetail = groupId => ({
  type: GET_GROUP_DETAIL,
  groupId,
})

export const getGroupDetailSuccess = groups => ({
  type: GET_GROUP_DETAIL_SUCCESS,
  payload: groups,
})

export const getGroupDetailFail = error => ({
  type: GET_GROUP_DETAIL_FAIL,
  payload: error,
})

export const updateGroup = group => ({
  type: UPDATE_GROUP,
  payload: group,
})

export const updateGroupSuccess = group => ({
  type: UPDATE_GROUP_SUCCESS,
  payload: group,
})

export const updateGroupFail = error => ({
  type: UPDATE_GROUP_FAIL,
  payload: error,
})

export const deleteGroup = group => ({
  type: DELETE_GROUP,
  payload: group,
})

export const deleteGroupSuccess = group => ({
  type: DELETE_GROUP_SUCCESS,
  payload: group,
})

export const deleteGroupFail = error => ({
  type: DELETE_GROUP_FAIL,
  payload: error,
})

export const clearGroupNotification = message => ({
  type: CLEAR_GROUP_NOTIFICATION,
  payload: null,
 })