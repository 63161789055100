/* GROUPS */
export const GET_GROUPS = "GET_GROUPS"
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS"
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL"

export const GET_GROUP_DETAIL = "GET_GROUP_DETAIL"
export const GET_GROUP_DETAIL_SUCCESS = "GET_GROUP_DETAIL_SUCCESS"
export const GET_GROUP_DETAIL_FAIL = "GET_GROUP_DETAIL_FAIL"

/**
 * add group
 */
export const ADD_NEW_GROUP = "ADD_NEW_GROUP"
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS"
export const ADD_GROUP_FAIL = "ADD_GROUP_FAIL"

/**
 * Edit group
 */
export const UPDATE_GROUP = "UPDATE_GROUP"
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS"
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL"

/**
 * Delete group
 */
export const DELETE_GROUP = "DELETE_GROUP"
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS"
export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL"

export const CLEAR_GROUP_NOTIFICATION = "CLEAR_GROUP_NOTIFICATION"


