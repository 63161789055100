import {
  GET_LOGS,
  GET_LOGS_FAIL,
  GET_LOGS_SUCCESS,
} from "./actionTypes"

export const getLogs = () => ({
  type: GET_LOGS,
})

export const getLogsSuccess = logs => ({
  type: GET_LOGS_SUCCESS,
  payload: logs,
})

export const getLogsFail = error => ({
  type: GET_LOGS_FAIL,
  payload: error,
})