import React, { useEffect, useState } from 'react';
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
// import NewSlider from './NewSlider';
import PageMaintenance from '../Utility/PageMaintenance';
import VAIKins from '../../assets/images/VAIKins.svg'
const Dashboard = () => {

    //meta title
    document.title = "Dashboard | VAIKins ";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
                    <Row>
                            <Card className='border border-primary'>
                                    <img src={VAIKins}/>
                            </Card>
                    </Row>
                </Container>
                {/* <PageMaintenance /> */}
            </div>
        </React.Fragment>
    );
}

export default Dashboard;