import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_USERS,
  GET_USER_DETAIL,
  ADD_NEW_USER,
  DELETE_USER,
  UPDATE_USER,
  UPDATE_PWD
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getUserDetailSuccess,
  getUserDetailFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  updatePasswordSuccess,
  updatePasswordFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUsers,
  getUserDetail,
  addNewUser,
  updateUser,
  deleteUser,
  updatePwd,
} from "../../helpers/backend_helper"

function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserDetail({ userId }) {
  try {
    const response = yield call(getUserDetail, userId)
    yield put(getUserDetailSuccess(response))
  } catch (error) {
    yield put(getUserDetailFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    yield put(updateUserSuccess(response))
  } catch (error) {
    // console.log(error)
    yield put(updateUserFail(error))
  }
}

function* updatePassword({ payload: pwd }) {
  try {
    const response = yield call(updatePwd, pwd)
    yield put(updatePasswordSuccess(response))
  } catch (error) {
    yield put(updatePasswordFail(error))
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    // console.log(user)
    const response = yield call(deleteUser, user)
    yield put(deleteUserSuccess(response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddNewUser({ payload: user }) {

  try {
    const response = yield call(addNewUser, user)
    yield put(addUserSuccess(response))
  } catch (error) {
    yield put(addUserFail(error))
  }
}

function* usersaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_DETAIL, fetchUserDetail)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(UPDATE_PWD, updatePassword)
}

export default usersaga
