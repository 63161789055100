import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link } from "react-router-dom"

import TableContainer from "../../components/Common/TableContainer"

import { toast } from 'react-toastify'

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Select from 'react-select';
import jwtDecode from "jwt-decode";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap";

import { isEmpty } from "lodash";

import {
  Title,
  Tabletext,
  TableStatus,
} from "../../components/Common/Tablecols";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getGroups as onGetGroups,
  addNewGroup as onAddNewGroup,
  updateGroup as onUpdateGroup,
  deleteGroup as onDeleteGroup,
  getProjects as onGetProjects,
  clearGroupNotification as onclearNotification
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux";
import { selectThemeDark, selectThemeLight } from "../../assets/selectTheme"

const GroupList = props => {

  //meta title
  document.title = "Group List | VAIKins ";

  const dispatch = useDispatch();
  const isAdmin = () => {
    const authToken = JSON.parse(localStorage.getItem("authorization"));

    if (authToken) {
      try {
        const decodedToken = jwtDecode(authToken.accessToken);
        return decodedToken.UserInfo.roles.includes("Admin");
      } catch (err) {
        console.log("Error decoding token:", err);
      }
    }

    return false;
  };
  const { groups, error, message, projects, layoutmode } = useSelector((state) => ({
    groups: state.group?.groups,
    error: state.group?.error,
    message: state.group?.message,
    projects: state.project?.projects,
    layoutmode: state.Layout?.layoutMode
  }));

  const [group, setGroup] = useState();
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isLoaded, setIsloaded] = useState(false)

  const toggleToast = () => {
    if (!isEmpty(message)) {
      toast.success(message); validation.resetForm(); setModal(false);
    }
    else if (error?.response?.data)
      toast.error(error.response.data.message)
    else if (error?.message)
      toast.error(error.message)
    dispatch(onclearNotification())
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: (group && group.id) || "",
      groupname: (group && group.groupname) || "",
      projects: (group && group.projects) || [],
      active: (group && group.active !== false),
    },
    validationSchema: Yup.object({
      groupname: Yup.string().required("Please enter the group name"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateGroup = {
          id: values.id,
          groupname: values.groupname,
          projects: values.projects,
          active: (String(values.active) === 'true')
        }
        // update group
        dispatch(onUpdateGroup(updateGroup));
      } else {
        // console.log(values)
        dispatch(onAddNewGroup(values, props.history));
      }
    },
  });

  const columns = useMemo(
    () => [
      // {
      //   Header: "Group ID",
      //   accessor: '_id',
      //   filterable: true,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     // return <Link className="fw-medium" to={`groups-detail/${cellProps.value}`}> {cellProps.value}</Link>;;
      //     return <Link className="fw-medium" to='#'> {cellProps.value}</Link>;;
      //   },
      // },
      {
        Header: "Group Name",
        accessor: "groupname",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          // return <Link className="fw-medium" to={`group-detail/${cellProps.row.original._id}`}>
          //   {cellProps.value}
          // </Link>
          return <Link className="fw-medium" to='#'> {cellProps.value}</Link>;
        },
      },
      // {
      //   Header: "Projects",
      //   accessor: "projects",
      //   filterable: true,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return <Tabletext {...cellProps} />;
      //   },
      // },
      {
        Header: "Status",
        accessor: "active",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <TableStatus {...cellProps} />
        },
      },
    ],
  );

  useEffect(() => {
    dispatch(onGetGroups());
    dispatch(onGetProjects());
    setIsEdit(false);
    setIsloaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded && (!isEmpty(message) || (error?.message || error?.response))) toggleToast();
    if (!isEmpty(message)) setIsEdit(false);
  }, [message, error])

  useEffect(() => {
    setGroup(groups);
    setIsEdit(false);
  }, [groups]);


  useEffect(() => {
    if (!isEmpty(groups) && !!isEdit) {
      setGroup(groups);
      setIsEdit(false);
    }
  }, [groups]);

  const toggle = () => {
    // if(modal)validation.resetForm()
    setModal(!modal);
  };

  const handleGroupClick = (arg) => {
    const { _id, groupname, projects, active } = arg; // Destructure the _id property from the arg object
    setGroup({
      id: _id, // Assign the _id property as the value of the id property
      groupname,
      projects,
      active,
    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = groups => {
    console.log(groups);
    const { _id, groupname, projects, active } = groups; // Destructure the _id property from the arg object
    setGroup({
      id: _id, // Assign the _id property as the value of the id property
      groupname,
      projects,
      active,
    });
    setDeleteModal(true);
  };

  const handleDeleteGroup = () => {
    dispatch(onDeleteGroup(group));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleGroupClicks = () => {
    setIsEdit(false);
    if (group) {
      // console.log(group);
      group.id = "",
        group.groupname = "",
        group.projects = [],
        group.active = ""
    }
    // console.log("Cleared")
    toggle();
  };

  const handleProjectSelectChange = (selectedOptions) => {
    const selectedProjects = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    validation.setFieldValue('projects', selectedProjects);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteGroup}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Groups" breadcrumbItem="Group List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <TableContainer
                        columns={columns}
                        data={groups}
                        isGlobalFilter={true}
                        isAddGroupList={true}
                        handleGroupClick={handleGroupClicks}
                        handleEditClick={handleGroupClick}
                        handleDelete={onClickDelete}
                        customPageSize={5}
                        isAdmin={isAdmin()}
                      />

                      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
                        <ModalHeader toggle={toggle} tag="h4">
                          {!!isEdit ? "Edit Group" : "Add Group"}
                        </ModalHeader>
                        <ModalBody>
                          <Form
                            onSubmit={e => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <Row>
                              <div className="mb-3">
                                <Label className="form-label">Groupname</Label>
                                <Input
                                  name="groupname"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.groupname || ""}
                                  invalid={
                                    validation.touched.groupname &&
                                      validation.errors.groupname
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.groupname &&
                                  validation.errors.groupname ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.groupname}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Projects</label>
                                <Select
                                  isMulti
                                  options={projects?.map((value) => ({
                                    label: value.title,
                                    value: value._id,
                                  }))}
                                  value={validation.values.projects.map((project) => ({
                                    value: project,
                                    label: projects.find((p) => p._id === project)?.title,
                                  }))}
                                  onChange={handleProjectSelectChange} // Assuming you have a separate handler for group selection
                                  getOptionLabel={(option) => option.label} // Specify the label property to display in the dropdown
                                  getOptionValue={(option) => option.value}
                                  theme={layoutmode === 'dark' ? selectThemeDark : selectThemeLight}
                                />
                                {validation.touched.projects && validation.errors.projects ? (
                                  <div className="text-danger">{validation.errors.projects}</div>
                                ) : null}
                              </div>

                              {isEdit && <div className="mb-3">
                                <label className="form-label">Status</label>
                                <Input type="select" name="active" className="form-select" multiple={false}
                                  onChange={(e) => { e.preventDefault(); console.log("Changed"); validation.setFieldValue("active", e.target.value === "true"); }}
                                  value={String(validation?.values?.active) === 'true'}
                                ><option value={'true'}>Active</option>
                                  <option value={'false'}>Inactive</option>
                                </Input>
                              </div>}
                            </Row>
                            <Row>
                              {!isEdit && <Col>
                                <div className="text-start">
                                  <Button
                                    name="form-reset"
                                    className="btn btn-danger"
                                    onClick={validation.resetForm}
                                  >
                                    Reset
                                  </Button>
                                </div>
                              </Col>}
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-group"
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GroupList;
