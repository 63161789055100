import {
  GET_LOGS_FAIL,
  GET_LOGS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  logs: [],
  error: {},
  message: "",
}

const Logs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOGS_SUCCESS:
      return {
        ...state,
        logs: action.payload,
      }

    case GET_LOGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Logs
