import axios from "axios"
import jwtDecode from "jwt-decode";
// import authHeader from "./jwt-token-access/auth-token-header"

//pass new generated access token here
// const token = authHeader()

//apply base url for axios
const API_URL = process.env.REACT_APP_BACKENDURL;

// const axios = axios.create({
//   baseURL: API_URL,
// })
axios.defaults.baseURL=API_URL
axios.defaults.withCredentials = true;


// axios.defaults.headers.common["Authorization"] = authHeader()

axios.interceptors.response.use(
  response => response,
  error => {
    if(JSON.parse(localStorage.getItem("authorization"))?.accessToken)
    {
    if (error.response.status === 498 && error.config && !error.config.__isRetryRequest) {
      // If 401 Unauthorized error and request hasn't already been retried
      error.config.__isRetryRequest = true; // Mark request as retried
      return axios.get('/auth/refresh').then(response => {
        const accessToken = response.data.accessToken;
        // Set new access token in cookie
        document.cookie = `access_token=${accessToken}; HttpOnly; SameSite=Strict;`;
        localStorage.setItem("authorization", JSON.stringify(response.data))
        // Update request headers with new access token and retry request
        error.config.headers.Authorization = `Bearer ${accessToken}`;
        return axios(error.config);
      }).catch(refreshError => {
        // If refresh token request also returns 401 Unauthorized error
        // Redirect user to login page and remove existing access and refresh tokens
        document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
        // localStorage.removeItem('authorization');
        // window.location.href = '/pages-440';
        // axios.post('/auth/logout')
        return Promise.reject(refreshError);
      });
    }}
    return Promise.reject(error);
  }
);


export async function get(url, config = {}) {
  // console.log(token)
  return await axios.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axios
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axios
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function patch(url, data, config = {}) {
  return axios
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, data, config = {}) {
  return await axios
    .delete(url, {data:data}, { ...config })
    .then(response => response.data)
}
