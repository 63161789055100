import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";

import SidebarContent from "./SidebarContent";

const Sidebar = (props) => {
  // Check if the user has admin role
  const isAdmin = () => {
    const authToken = JSON.parse(localStorage.getItem("authorization"));

    if (authToken) {
      try {
        const decodedToken = jwtDecode(authToken.accessToken);
        return decodedToken.UserInfo.roles.includes("Admin");
      } catch (err) {
        console.log("Error decoding token:", err);
      }
    }

    return false;
  };

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? (
            <SidebarContent isAdmin={isAdmin()} />
          ) : (
            <SidebarContent isAdmin={isAdmin()} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStatetoProps, {})(Sidebar);
