import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import jwtDecode from "jwt-decode"
const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isAdminRoutes,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      try {
        var token = jwtDecode(JSON.parse(localStorage.getItem("authorization"))?.accessToken)
        // console.log("middleware",token)
        if (!token.UserInfo.roles.includes("Admin") && isAdminRoutes) {
          return (
            <Redirect
              to={{ pathname: "/Dashboard", state: { from: props.location } }}
            />
          );
        }

        if ((token.exp * 1000 < Date.now()) && isAuthProtected) {
          return (
            <Redirect
              to={{ pathname: "/pages-440", state: { from: props.location } }}
            />
          )
        }
      } catch (err) {
        if (isAuthProtected && err) {
          console.log("Unauthorized", err)
          localStorage.removeItem("authorization")
        }
      }
      if (isAuthProtected && !localStorage.getItem("authorization")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
