import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link } from "react-router-dom"

import TableContainer from "../../components/Common/TableContainer"

import { toast } from 'react-toastify'

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import jwtDecode from "jwt-decode";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap";

import { isEmpty } from "lodash";

import {
  Title,
  Tabletext,
  DateTime
} from "../../components/Common/Tablecols";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getProjects as onGetProjects,
  addNewProject as onAddNewProject,
  updateProject as onUpdateProject,
  deleteProject as onDeleteProject,
  clearNotification
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux";

const ProjectList = props => {

  //meta title
  document.title = "Project List | VAIKins ";

  const dispatch = useDispatch();

  const { projects, error, message } = useSelector((state) => ({
    projects: state.project?.projects,
    error: state.project?.error,
    message: state.project?.message,
  }));
  const isAdmin = () => {
    const authToken = JSON.parse(localStorage.getItem("authorization"));

    if (authToken) {
      try {
        const decodedToken = jwtDecode(authToken.accessToken);
        return decodedToken.UserInfo.roles.includes("Admin");
      } catch (err) {
        console.log("Error decoding token:", err);
      }
    }

    return false;
  };
  const [project, setProject] = useState();
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isLoaded, setIsloaded] = useState(false)

  const toggleToast = () => {
    if (!isEmpty(message)) {
      toast.success(message); validation.resetForm(); setModal(false);
    }
    else if (error?.response?.data)
      toast.error(error.response.data.message)
    else if (error?.message)
      toast.error(error.message)
    dispatch(clearNotification())
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: (project && project.id) || "",
      title: (project && project.title) || "",
      gitUrl: (project && project.gitUrl) || "",
      ipaddr: (project && project.ipaddr) || "",
      gitBranch: (project && project.gitBranch) || "main",
      projectType: (project && project.projectType) || "frontend",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter the project Title"),
      gitUrl: Yup.string()
        .required("Please enter the project repo URL")
        .matches(
          /^(?:git@github\.com:)[^\s/]+\/[^\s/]+\.git$/,
          "Invalid GitHub SSH repository URL"
        ),
      ipaddr: Yup.string()
        .required("Please provide server IP address")
        .test('ip-address', 'Invalid IP address', (value) => {
          // Regular expression to validate IP address format
          const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
          return ipRegex.test(value);
        }),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateProject = {
          id: values.id,
          title: values.title,
          gitUrl: values.gitUrl,
          ipaddr: values.ipaddr,
          gitBranch: values.gitBranch,
          projectType: values.projectType
        }
        // update project
        dispatch(onUpdateProject(updateProject));
      } else {
        // console.log(...formData)
        dispatch(onAddNewProject(values, props.history));
      }
    },
  });

  const columns = useMemo(
    () => [
      // {
      //   Header: "Project ID",
      //   accessor: '_id',
      //   filterable: true,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return (
      //       <Link className="fw-medium" to={`projects-detail/${cellProps.value}`}>
      //         {cellProps.value}
      //       </Link>
      //     );
      //   },
      // },
      {
        Header: "Project Title",
        accessor: "title",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Link className="fw-medium" to={`projects-detail/${cellProps.row.original._id}`}>
              {cellProps.value}
            </Link>
          );
        },
      },
      {
        Header: "GitUrl",
        accessor: "gitUrl",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Tabletext {...cellProps} />;
        },
      },
      {
        Header: "Server IP",
        accessor: "ipaddr",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Tabletext {...cellProps} />;
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(onGetProjects());
    setIsEdit(false);
    setIsloaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded && (!isEmpty(message) || (error?.message || error?.response))) toggleToast();
    if (!isEmpty(message)) setIsEdit(false);
  }, [message, error])

  useEffect(() => {
    setProject(projects);
    setIsEdit(false);
  }, [projects]);


  useEffect(() => {
    if (!isEmpty(projects) && !!isEdit) {
      setProject(projects);
      setIsEdit(false);
    }
  }, [projects]);

  const toggle = () => {
    // if(modal)validation.resetForm()
    setModal(!modal);
  };

  const handleProjectClick = (arg) => {
    const sproject = projects.filter(projects => { return projects._id === arg._id })
    const project = sproject[0]
    // console.log("project",project)
    setProject({
      id: project._id,
      title: project.title,
      gitUrl: project.gitUrl,
      ipaddr: project.ipaddr,
      gitBranch: project.gitBranch,
      projectType: project.projectType
    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = projects => {
    // console.log(projects);
    setProject(projects);
    setDeleteModal(true);
  };

  const handleDeleteProject = () => {
    dispatch(onDeleteProject(project));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleProjectClicks = () => {
    setIsEdit(false);
    if (project) {
      // console.log(project);
      project.id = "",
        project.title = "",
        project.gitUrl = "",
        project.ipaddr = "",
        project.gitBranch = "",
        project.projectType = ""
    }
    // console.log("Cleared")
    toggle();
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProject}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Project List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <TableContainer
                        columns={columns}
                        data={projects}
                        isGlobalFilter={true}
                        isAddProjectList={true}
                        handleProjectClick={handleProjectClicks}
                        handleEditClick={handleProjectClick}
                        handleDelete={onClickDelete}
                        customPageSize={5}
                        isAdmin={isAdmin()}
                      />

                      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
                        <ModalHeader toggle={toggle} tag="h4">
                          {!!isEdit ? "Edit Project" : "Add Project"}
                        </ModalHeader>
                        <ModalBody>
                          <Form
                            onSubmit={e => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <Row>
                              <div className="mb-3">
                                <Label className="form-label">Title</Label>
                                <Input
                                  name="title"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.title || ""}
                                  invalid={
                                    validation.touched.title &&
                                      validation.errors.title
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.title &&
                                  validation.errors.title ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.title}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">GitURL</Label>
                                <Input
                                  name="gitUrl"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.gitUrl || ""}
                                  invalid={
                                    validation.touched.gitUrl &&
                                      validation.errors.gitUrl
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.gitUrl &&
                                  validation.errors.gitUrl ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.gitUrl}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Git Branch</Label>
                                <div className="d-flex">
                                  <Label className="me-3">
                                    <Input
                                      type="radio"
                                      name="gitBranch"
                                      value="main"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      checked={validation.values.gitBranch === "main"}
                                    />{" "}
                                    Main
                                  </Label>
                                  <Label className="me-3">
                                    <Input
                                      type="radio"
                                      name="gitBranch"
                                      value="develop"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      checked={validation.values.gitBranch === "develop"}
                                    />{" "}
                                    Develop
                                  </Label>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="gitBranch"
                                      value="qa"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      checked={validation.values.gitBranch === "qa"}
                                    />{" "}
                                    QA
                                  </Label>
                                </div>
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Project Type</Label>
                                <div className="d-flex">
                                  <Label className="me-3">
                                    <Input
                                      type="radio"
                                      name="projectType"
                                      value="frontend"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      checked={validation.values.projectType === "frontend"}
                                    />{" "}
                                    Frontend
                                  </Label>
                                  <Label className="me-3">
                                    <Input
                                      type="radio"
                                      name="projectType"
                                      value="backend"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      checked={validation.values.projectType === "backend"}
                                    />{" "}
                                    Backend
                                  </Label>
                                </div>
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Server IP</label>
                                <Input
                                  name="ipaddr"
                                  mask="999.999.999.999"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.ipaddr || ""}
                                  className={
                                    `form-control ${validation.touched.ipaddr && validation.errors.ipaddr ? 'is-invalid' : ''}`
                                  }
                                />
                                {validation.touched.ipaddr && validation.errors.ipaddr ? (
                                  <div className="invalid-feedback">
                                    {validation.errors.ipaddr}
                                  </div>
                                ) : null}
                              </div>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-start">
                                  <Button
                                    name="form-reset"
                                    className="btn btn-danger"
                                    onClick={validation.resetForm}
                                  >
                                    Reset
                                  </Button>
                                </div>
                              </Col>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default ProjectList;
