import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//import images
import logo from "../../assets/images/logo3d.svg";

const PageMaintenance = () => {
    return (
        <React.Fragment>
            <div className="bg-soft-light min-vh-100 py-5">
                <div className="py-4">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <div className="mb-5">
                                        <Link to="/dashboard">
                                            <img src={logo} alt="" height="30" className="me-1" /><span className="logo-txt text-dark font-size-22">VAIKins</span>
                                        </Link>
                                    </div>

                                    <div className="maintenance-cog-icon text-primary pt-4">
                                        <i className="mdi mdi-cog spin-right display-3"></i>
                                        <i className="mdi mdi-cog spin-left display-4 cog-icon"></i>
                                    </div>
                                    <h3 className="mt-4">Dashboard Under Maintenance</h3>
                                    <p>Please check <Link to="/projects-list">Projects</Link> for now.</p>

                                    <div className="mt-4">
                                        <Row>
                                            <Col md={4}>
                                                <div className="mt-4 maintenance-box">
                                                    <div className="p-4">
                                                        <div className="avatar-md mx-auto">
                                                            <span className="avatar-title bg-soft-primary rounded-circle">
                                                                <i className="mdi mdi-access-point-network font-size-24 text-primary"></i>
                                                            </span>
                                                        </div>

                                                        <h5 className="font-size-15 text-uppercase mt-4">Why is the Page Down?</h5>
                                                        <p className="text-muted mb-0">Adding more features</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="mt-4 maintenance-box">
                                                    <div className="p-4">
                                                        <div className="avatar-md mx-auto">
                                                            <span className="avatar-title bg-soft-primary rounded-circle">
                                                                <i className="mdi mdi-clock-outline font-size-24 text-primary"></i>
                                                            </span>
                                                        </div>
                                                        <h5 className="font-size-15 text-uppercase mt-4">
                                                            What is the Downtime?</h5>
                                                        <p className="text-muted mb-0">Probably a week</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="mt-4 maintenance-box">
                                                    <div className="p-4">
                                                        <div className="avatar-md mx-auto">
                                                            <span className="avatar-title bg-soft-primary rounded-circle">
                                                                <i className="mdi mdi-email-outline font-size-24 text-primary"></i>
                                                            </span>
                                                        </div>
                                                        <h5 className="font-size-15 text-uppercase mt-4">
                                                            Do you need Support?</h5>
                                                        <p className="text-muted mb-0">If you need help, <br/>contact- <a
                                                                href="mailto:himanshu.rathi@vaimanagement.co"
                                                                className="text-decoration-underline text-dark fw-bold">Somebody</a></p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PageMaintenance;