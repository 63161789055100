import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

//projects
import project from "./projects/reducer"

//Users
import user from "./users/reducer"

//Groups
import group from "./groups/reducer"

//logs
import log from "./logs/reducer"

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  project,
  user,
  group,
  log
})

const rootReducer = (state,action) =>{
  if(action.type === 'LOGOUT_USER_SUCCESS')
  {return appReducer(undefined,action)}
  return appReducer(state,action)
}

export default rootReducer
