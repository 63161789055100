import PropTypes from "prop-types"
import React, { useEffect, useRef, useCallback } from "react"
import { Link, withRouter } from "react-router-dom"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"

const SidebarContent = (props) => {
  const ref = useRef()

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }, []);


  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu",{
        toggle: "hover",
      })
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu</li>
            <li>
              <Link to="/dashboard">
                <i className="mdi mdi-home fw-bold text-dark" />
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/projects-list">
                <i className="mdi mdi-format-list-bulleted fw-bold text-dark" />
                <span>Project List</span>
              </Link>
            </li>
            {props.isAdmin && (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="mdi mdi-tune-vertical fw-bold text-dark" />
                    <span>Admin Control</span>
                  </Link>
                  <ul>
                    <li>
                      <Link to="/users-list">
                        <i className="mdi mdi-account-cog fw-bold text-dark"/>
                        <span>Users</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/groups-list">
                        <i className="mdi mdi-server fw-bold text-dark"/>
                        <span>Servers</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fw-bold text-dark mdi mdi-file" />
                    <span>Logs</span>
                  </Link>
                  <ul>
                    <li>
                      <Link to="/server-logs">
                      <i className="fw-bold text-dark mdi mdi-file-document" />
                        <span>Server Logs</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/project-logs">
                      <i className="fw-bold text-dark mdi mdi-file-document" />
                        <span>Project Logs</span>
                      </Link>
                    </li>
                  </ul>
                </li>

              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  isAdmin: PropTypes.bool,
}

export default withRouter(SidebarContent)
