import React, { useEffect, useRef, useState } from "react"
import { Card, CardBody, Col, Container, Label, Input, Row, Modal, ModalBody, Button } from "reactstrap"
import { isEmpty, map } from "lodash"
import AnsiToHtml from 'ansi-to-html';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { toast } from 'react-toastify'

//Import Image
import logo from "../../assets/images/logo3d.svg"
import {
  getProjectDetail as onGetProjectDetail,
  deployProject as onDeployProject,
  clearNotification as onClearNotification,
} from "../../store/projects/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import DeleteModal from "../../components/Common/DeleteModal";
import { post } from "../../helpers/api_helper";

const ProjectDetail = props => {

  //meta title
  document.title = "Project Detail | VAIKins ";

  const dispatch = useDispatch()
  const [isDeploying, setIsDeploying] = useState(false)
  const [isCheckingStatus, setIsCheckingStatus] = useState(false)
  const [isDeployConfirm, setIsDeployConfirm] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isReverting, setIsReverting] = useState(false)
  const [isStopping, setIsStopping] = useState(false)
  const [isLogging, setIsLogging] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [isLoaded, setIsloaded] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false);
  const [output, setOutput] = useState('Hello Mate! \nTry pressing the deploy button to see magic!');
  const [envData, setEnvData] = useState('');
  const [isScriptModal, setIsScriptModal] = useState(false)
  const [customScript, setCustomScript] = useState('')
  const [isRunningScript, setIsRunningScript] = useState(false)
  const { projectDetail, message, error } = useSelector(state => (
    {
      projectDetail: state.project?.projectDetail,
      message: state.project?.message,
      error: state.project?.error
    }
  ))

  const {
    match: { params },
  } = props

  const preRef = useRef(null);
  const socketRef = useRef(null);

  useEffect(() => {
    if (output && preRef.current) {
      const ansiToHtml = new AnsiToHtml();
      const htmlOutput = ansiToHtml.toHtml(output);
      preRef.current.innerHTML = htmlOutput;
      preRef.current.scrollTop = preRef.current.scrollHeight;
    }
  }, [output]);


  useEffect(() => {
    if (params && params.id) {
      dispatch(onGetProjectDetail(params.id))
    } else {
      // dispatch(onGetProjectDetail(1)) //remove this after full integration
    }
    setIsloaded(true)
  }, [dispatch])

  useEffect(() => {
    if (isLoaded && !isEmpty(message)) toggleToast();
  }, [message])

  //toggle toast
  const toggleToast = () => {
    if (!isEmpty(message))
      toast.success(message)
    dispatch(onClearNotification())
  };
  //Print the Project
  const printProject = () => {
    window.print()
  }
  const onClickConfirm = () => {
    setConfirmModal(true);
  };
  const deploytoggle = () => {
    setIsDeployConfirm(!isDeployConfirm)
  }
  const customScriptToggle = () => {
    setIsScriptModal(!isScriptModal)
  }
  const handleWebSocket = (action, TargetRoute, payload, eventMessage) => {
    setOutput('');
    const socket = new WebSocket(`${process.env.REACT_APP_WS_URL}`);
    socket.onmessage = (event) => {
      const ws_message = event.data;
      try {
        const parsedMessage = JSON.parse(ws_message);

        if (parsedMessage.error) {
          toast.error(parsedMessage.error)
        }
      } catch(err){
        if(action.toString()!=='fetch')
        setOutput((prevOutput) => prevOutput + ws_message);
        if(action.toString()==='fetch')
        setEnvData((prevOutput) => prevOutput + ws_message);
      }
    };

    socket.onclose = () => {
      // console.log('Connection closed');
      switch (action) {
        case 'run':
          setIsDeploying(false);
          break;
        case 'status':
          setIsCheckingStatus(false);
          break;
        case 'custom':
          setIsRunningScript(false);
          break;
        case 'stop':
          setIsStopping(false);
          break;
        case 'logs':
          setIsLogging(false);
          break;
        case 'delete':
          setIsDeleting(false);
          break;
        default:
          break;
      }
    };

    socket.onerror = (error) => {
      toast.error("Websocket error: "+error);
    };

    socket.onopen = () => {
      switch (action) {
        case 'fetch':
          setEnvData('');;
          setIsDeployConfirm(true);
          break;
        case 'run':
          deploytoggle();
          setIsDeploying(true);
          break;
        case 'status':
          setIsCheckingStatus(true);
          break;
        case 'stop':
          setIsStopping(true);
          break;
        case 'custom':
          customScriptToggle();
          setIsRunningScript(true);
          break;
        case 'delete':
          setDeleteModal(false);
          setIsDeleting(true);
          break;
        case 'logs':
          setIsLogging(true);
          break;
        default:
          break;
      }
      socket.send(JSON.stringify({
        action: 'connect',
        targetServerUrl: `ws://${projectDetail.ipaddr}`,
        TargetRoute,
        payload,
      }));

      try {
        const response = post('/logs', { event: eventMessage, projectID: projectDetail?._id });
      } catch (err) {
        console.log(err);
      }
    };
  };

  const handleENVfetch = () => {
    handleWebSocket('fetch', 'fetch', `${projectDetail.gitUrl},${projectDetail._id}`, 'Fetched Env Variable');
  };

  const handleDeployProject = () => {
    handleWebSocket('run', 'run', `${projectDetail.gitUrl},${envData},${projectDetail._id},${projectDetail.gitBranch},${projectDetail.projectType}`, 'Deployed the project');
  };

  const handleStatusProject = () => {
    handleWebSocket('status', 'status', `${projectDetail._id}`, 'Checked status of the project');
  };

  const handleCustomScript = () => {
    handleWebSocket('custom', 'custom', `${projectDetail.gitUrl},${customScript},${projectDetail._id}`, `Ran these commands "${customScript}" to the project directory`);
  };

  const handleDeleteProjectclick = () => {
    setDeleteModal(true);
  };

  const handleDeleteProject = () => {
    handleWebSocket('delete', 'delete', `${projectDetail.gitUrl},${projectDetail._id}`, 'Deleted the project');
  };

  const handleStopProject = () => {
    handleWebSocket('stop', 'stop', `${projectDetail._id}`, 'Stopped the project');
  };

  const handleLogsProject = () => {
    if (isLogging) {
      socketRef.current.close();
      try {
        const response = post('/logs', { event: 'Stopped pm2 logs', projectID: projectDetail?._id });
      } catch (err) {
        console.log(err);
      }
    } else {
      setOutput('');
      const socket = new WebSocket(`${process.env.REACT_APP_WS_URL}`);
      socket.onmessage = (event) => {
        setOutput((prevOutput) => prevOutput + event.data);
      };
      socket.onclose = () => {
        // toast.info('Connection closed');
        setIsLogging(false);
      };
      socket.onerror = (error) => {
        toast.error("Websocket error");
        console.log('WebSocket error:', error);
      };
      socket.onopen = () => {
        socket.send(JSON.stringify({
          action: 'connect',
          targetServerUrl: `ws://${projectDetail.ipaddr}`,
          TargetRoute: 'logs',
          payload: `${projectDetail._id}`,
        }));
        setIsLogging(true);
        try {
          const response = post('/logs', { event: 'Started pm2 logs', projectID: projectDetail?._id });
        } catch (err) {
          console.log(err);
        }
      };
      socketRef.current = socket;
    }
  };
  const handleRevertProjectclick = () => {
    toast.info("Implementation pending")
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProject}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs linkurl='/projects-list' title="Projects" breadcrumbItem="Project Detail" />
          {!isEmpty(projectDetail) && (
            <>
              <Card>
                <CardBody>
                  <Row>
                    <div className="project-title">
                      <Row>
                        <Col>
                          <img src={logo} alt="" height="24" /><span className="logo-txt">VAIKins</span>
                        </Col>
                        <Col>
                          <p className="float-end font-size-16">Project #  <span className="font-size-15 fw-medium" style={{ "wordBreak": "break-all" }}>{projectDetail._id}</span></p>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col lg={3}>
                        <Row>
                          <h5 className="font-size-15">Project Title: <span className="fw-medium font-size-15 badge bg-primary float-end">{projectDetail.title}</span></h5>
                          <h5 className="font-size-15">Server IP: <span className="fw-medium font-size-15 badge bg-primary float-end">{projectDetail.ipaddr}</span></h5>
                        </Row>
                        <Row>
                          <h5 className="font-size-15">Project Url: <a className="font-size-15 float-end" style={{ "wordBreak": "break-all" }} title={projectDetail.gitUrl} href={"https://" + projectDetail.gitUrl.replace("git@github.com:", "github.com/")} target="_blank">{projectDetail.gitUrl.substring(15, 21) + "..." + projectDetail.gitUrl.substring(projectDetail.gitUrl.length - 4)}</a></h5>
                        </Row>
                        <div className="d-grid flex-wrap gap-2">
                          <button
                            name="deploy-btn"
                            disabled={isReverting || isDeploying || isDeleting || isStopping || isLogging || isCheckingStatus}
                            className="btn btn-success"
                            onClick={e => {
                              e.preventDefault();
                              handleENVfetch();
                            }}
                          >
                            {isDeploying ? (
                              <>
                                <i className="mdi mdi-loading  mdi-spin me-1" />
                                Deploying...
                              </>
                            ) : (
                              <>
                                <i className="mdi mdi-cog-play  me-1" />
                                Deploy
                              </>
                            )}
                          </button>
                          <button
                            name="status-btn"
                            disabled={isReverting || isDeploying || isDeleting || isStopping || isLogging || isCheckingStatus}
                            className="btn btn-info"
                            onClick={e => {
                              e.preventDefault();
                              handleStatusProject();
                            }}
                          >
                            {isCheckingStatus ? (
                              <>
                                <i className="mdi mdi-loading  mdi-spin me-1" />
                                Checking...
                              </>
                            ) : (
                              <>
                                <i className="mdi mdi-alert-circle-outline  me-1" />
                                Check Status
                              </>
                            )}
                          </button>
                          <button
                            name="stop-btn"
                            color={'warning'}
                            disabled={isReverting || isDeploying || isDeleting || isStopping || isLogging || isCheckingStatus}
                            className="btn btn-warning"
                            onClick={e => {
                              e.preventDefault();
                              handleStopProject();
                            }}
                          >
                            {isStopping ? (
                              <>
                                <i className="mdi mdi-loading  mdi-spin me-1" />
                                Stopping...
                              </>
                            ) : (
                              <>
                                <i className="mdi mdi-stop-circle-outline  me-1" />
                                Stop Process
                              </>
                            )}
                          </button>
                          <button
                            name="logs-btn"
                            disabled={isReverting || isDeploying || isDeleting || isStopping || isCheckingStatus}
                            className={`btn btn-${isLogging ? 'danger' : 'success'}`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleLogsProject();
                            }}
                          >
                            {!isLogging ? (
                              <>
                                <i className="mdi mdi-file me-1" />
                                Start Logs
                              </>
                            ) : (
                              <>
                                <i className="mdi mdi-stop me-1" />
                                Stop Logs
                              </>
                            )}
                          </button>
                          <button
                            name="delete-btn"
                            disabled={isReverting || isDeploying || isDeleting || isStopping || isLogging || isCheckingStatus}
                            className="btn btn-teal"
                            onClick={handleRevertProjectclick}
                          >
                            {isReverting ? (
                              <>
                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true" />
                                Reverting...
                              </>
                            ) : (
                              <>
                                <i className="mdi mdi-undo me-1" />{" "}
                                Revert
                              </>
                            )}
                          </button>
                          <button
                            name="script-btn"
                            disabled={isReverting || isDeploying || isDeleting || isStopping || isLogging || isCheckingStatus}
                            className="btn btn-dark"
                            onClick={customScriptToggle}
                          >
                            {isReverting ? (
                              <>
                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true" />
                                Running...
                              </>
                            ) : (
                              <>
                                <i className="mdi mdi-script me-1" />{" "}
                                Custom Script
                              </>
                            )}
                          </button>
                          <button
                            title="Delete"
                            disabled={isReverting || isDeploying || isDeleting || isStopping || isLogging || isCheckingStatus}
                            className="btn btn-danger mb-2"
                            onClick={(e) => { e.preventDefault(); handleDeleteProjectclick() }}
                          >
                            <i className="mdi mdi-delete-forever">
                            </i>
                            {" "}Delete Project
                          </button>
                        </div>
                      </Col>
                      <Col lg={9}>
                        <pre
                          ref={preRef}
                          style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            maxHeight: '70vh',
                            minHeight: '70vh',
                            backgroundColor: 'black',
                            color: 'white',
                            padding: '10px',
                            fontFamily: 'Courier New, monospace',
                            fontSize: '12px',
                            lineHeight: '1.5',
                            whiteSpace: 'pre-wrap',
                            fontWeight: 'bold',
                          }}
                        ></pre>
                      </Col>
                    </Row>
                  </Row>
                </CardBody>
              </Card>
              <Modal size="xl" isOpen={isDeployConfirm} toggle={deploytoggle}>
                <ModalBody>
                  <Row className="mb-2">
                    <Label className="text-danger">Please verify the .env file and if required change the content before submitting</Label>
                    <Input type="textarea"
                      style={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        maxHeight: '70vh',
                        minHeight: '70vh',
                        backgroundColor: 'black',
                        color: 'white',
                        padding: '10px',
                        fontFamily: 'Courier New, monospace',
                        fontSize: '14px',
                        lineHeight: '1.5',
                        whiteSpace: 'pre-wrap', // Add this line to make the text wrap
                      }}
                      value={envData}
                      onChange={(e) => setEnvData(e.target.value)}
                    ></Input>
                  </Row>
                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      name="submit-btn"
                      className="btn btn-info me-2"
                      onClick={e => {
                        e.preventDefault()
                        handleDeployProject()
                      }}
                    >Submit</Button>
                  </div>
                </ModalBody>
              </Modal>
              <Modal size="xl" isOpen={isScriptModal} toggle={customScriptToggle}>
                <ModalBody>
                  <Row className="mb-2">
                    <Label className="text-danger">Please enter the commands seperated by ";" that you want to run in the project directory</Label>
                    <Input type="textarea"
                      style={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        maxHeight: '70vh',
                        minHeight: '70vh',
                        backgroundColor: 'black',
                        color: 'white',
                        padding: '10px',
                        fontFamily: 'Courier New, monospace',
                        fontSize: '14px',
                        lineHeight: '1.5',
                        whiteSpace: 'pre-wrap', // Add this line to make the text wrap
                      }}
                      value={customScript}
                      onChange={(e) => setCustomScript(e.target.value)}
                    ></Input>
                  </Row>
                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      name="submit-btn"
                      className="btn btn-info me-2"
                      onClick={e => {
                        e.preventDefault()
                        handleCustomScript()
                      }}
                    >Submit</Button>
                  </div>
                </ModalBody>
              </Modal>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectDetail