import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import projectsaga from "./projects/saga"
import userSaga from "./users/saga"
import groupSaga from "./groups/saga"
import logSaga from "./logs/saga"


export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(projectsaga),
    fork(userSaga),
    fork(groupSaga),
    fork(logSaga)
  ])
}
