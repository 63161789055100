import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

//Utility
import PageMaintenance from "../pages/Utility/PageMaintenance";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";
import Error440 from "../pages/Utility/Error440";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ProjectList from "../pages/Projects/projects-list";
import ProjectDetail from "../pages/Projects/projects-detail";
import UserList from "../pages/Users/users-list";
// import UserDetail from "../pages/Users/users-detail";
import GroupList from "../pages/Groups/groups-list";
// import GroupDetail from "../pages/Groups/groups-detail";
import ServerLogs from "../pages/Logs/serverlogs.js";
import ProjectLogs from "../pages/Logs/project-logs";



const userRoutes = [
  //dashboard
  { path: "/dashboard", component: Dashboard },

  //Project
  { path: "/projects-list", component: ProjectList },
  { path: "/projects-detail/:id", component: ProjectDetail },

  // this route should be at the end of all other routes
  { path: "/*", exact: true, component: () => <Redirect to="/dashboard" /> },
];
const adminRoutes = [
  { path: "/users-list", component: UserList },
  // { path: "/users-detail/:id", component: UserDetail },
  { path: "/groups-list", component: GroupList },
  // { path: "/groups-detail/:id", component: GroupDetail },
  { path: "/server-logs", component: ServerLogs },
  { path: "/project-logs", component: ProjectLogs },
]

const authRoutes = [
  //authencation page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  //Utility page
  { path: "/pages-maintenance", component: PageMaintenance },
  { path: "/pages-404", component: Error404 },
  { path: "/pages-440", component: Error440 },
  { path: "/pages-500", component: Error500 },
];

export { userRoutes, authRoutes, adminRoutes };
