import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link } from "react-router-dom"

import TableContainer from "../../components/Common/TableContainer"

import { toast } from 'react-toastify'

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Select from 'react-select';
import jwtDecode from "jwt-decode";
import {selectThemeDark, selectThemeLight } from "../../assets/selectTheme"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap";

import { isEmpty } from "lodash";

import {
  Title,
  Tabletext,
  TableStatus,
} from "../../components/Common/Tablecols";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
  getGroups as onGetGroups,
  clearUserNotification as onclearNotification
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux";

const UserList = props => {

  //meta title
  document.title = "User List | VAIKins ";

  const dispatch = useDispatch();
  const isAdmin = () => {
    const authToken = JSON.parse(localStorage.getItem("authorization"));

    if (authToken) {
      try {
        const decodedToken = jwtDecode(authToken.accessToken);
        return decodedToken.UserInfo.roles.includes("Admin");
      } catch (err) {
        console.log("Error decoding token:", err);
      }
    }

    return false;
  };
  const { users, error, message, groups, layoutmode } = useSelector((state) => ({
    users: state.user?.users,
    error: state.user?.error,
    message: state.user?.message,
    groups: state.group?.groups,
    layoutmode: state.Layout?.layoutMode
  }));
  const rolesoptions = [
    { value: 'Member', label: 'Member' },
    { value: 'Admin', label: 'Admin' },
  ];
  const [user, setUser] = useState();
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isLoaded, setIsloaded] = useState(false)

  const toggleToast = () => {
    if (!isEmpty(message)) {
      toast.success(message); validation.resetForm(); setModal(false);
    }
    else if (error?.response?.data)
      toast.error(error.response.data.message)
    else if (error?.message)
      toast.error(error.message)
    dispatch(onclearNotification())
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: (user && user.id) || "",
      username: (user && user.username) || "",
      department: (user && user.department) || "",
      password: (user && user.password) || "",
      active: (user && user.active !== false),
      roles: (user && user.roles) || ['Member'],
      groups: (user && user.groups) || [],
      isEdit: isEdit
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Please enter the Username"),
      password: Yup.string().when('isEdit', { is: false, then: Yup.string().required("Please enter password") }),
      department: Yup.string().required("Please enter the user Department"),
      roles: Yup.array().min(1, 'Please select at least one option.')
        .required('Please select at least one option.'),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: values.id,
          username: values.username,
          department: values.department,
          roles: values.roles,
          groups: values.groups,
          password: values.password,
          active: (String(values.active) === 'true'),
        }
        // update user
        dispatch(onUpdateUser(updateUser));
      } else {
        // console.log("Before sumit", values)
        dispatch(onAddNewUser(values, props.history));
      }
    },
  });

  const columns = useMemo(
    () => [
      // {
      //   Header: "User ID",
      //   accessor: '_id',
      //   filterable: true,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     // return <Link className="fw-medium" to={`users-detail/${cellProps.value}`}> {cellProps.value}</Link>;;
      //     return <Link className="fw-medium" to='#'> {cellProps.value}</Link>;;
      //   },
      // },
      {
        Header: "Username",
        accessor: "username",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          // return <Link className="fw-medium" to={`user-detail/${cellProps.row.original._id}`}>
          //   {cellProps.value}
          // </Link>
          return <Link className="fw-medium" to='#'> {cellProps.value}</Link>;
        },
      },
      {
        Header: "Department",
        accessor: "department",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Tabletext {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "active",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <TableStatus {...cellProps} />
        },
      },
    ],
  );

  useEffect(() => {
    dispatch(onGetUsers());
    dispatch(onGetGroups());
    setIsEdit(false);
    setIsloaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded && (!isEmpty(message) || (error?.message || error?.response))) toggleToast();
    if (!isEmpty(message)) setIsEdit(false);
  }, [message, error])

  useEffect(() => {
    setUser(users);
    setIsEdit(false);
  }, [users]);


  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setUser(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    // if(modal)validation.resetForm()
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const suser = users.filter(users => { return users._id === arg._id })
    const user = suser[0]
    // console.log("user",user)
    setUser({
      id: user._id,
      username: user.username,
      department: user.department,
      active: user.active,
      roles: user.roles,
      groups: user.groups
    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    console.log(users);
    setUser({
      id: users._id
    });
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    dispatch(onDeleteUser(user));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    if (user) {
      // console.log(user);
      user.id = "",
        user.username = "",
        user.department = "",
        user.active = "",
        user.groups = "",
        user.roles = ""
    }
    // console.log("Cleared")
    toggle();
  };

  const handleRoleSelectChange = (selectedOptions) => {
    const selectedRoles = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    validation.setFieldValue('roles', selectedRoles);
  };

  const handleGroupSelectChange = (selectedOptions) => {
    const selectedGroups = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    validation.setFieldValue('groups', selectedGroups);
  };
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Users" breadcrumbItem="User List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <TableContainer
                        columns={columns}
                        data={users}
                        isGlobalFilter={true}
                        isAddUserList={true}
                        handleUserClick={handleUserClicks}
                        handleEditClick={handleUserClick}
                        handleDelete={onClickDelete}
                        customPageSize={5}
                        isAdmin={isAdmin()}
                      />

                      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
                        <ModalHeader toggle={toggle} tag="h4">
                          {!!isEdit ? "Edit User" : "Add User"}
                        </ModalHeader>
                        <ModalBody>
                          <Form
                            onSubmit={e => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <Row>
                              <div className="mb-3">
                                <Label className="form-label">Username</Label>
                                <Input
                                  name="username"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.username || ""}
                                  disabled={isEdit}
                                  invalid={
                                    validation.touched.username &&
                                      validation.errors.username
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.username &&
                                  validation.errors.username ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.username}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Password</Label>
                                <Input
                                  name="password"
                                  type="password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.password || ""}
                                  invalid={
                                    validation.touched.password &&
                                      validation.errors.password
                                      ? true
                                      : false
                                  }
                                />{isEdit && <span className="text-danger">Leave it blank if you don't want to change password</span>
                                }
                                {validation.touched.password &&
                                  validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Department</Label>
                                <Input
                                  name="department"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.department || ""}
                                  invalid={
                                    validation.touched.department &&
                                      validation.errors.department
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.department &&
                                  validation.errors.department ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.department}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Role</label>
                                <Select
                                  isMulti
                                  options={rolesoptions}
                                  value={validation.values.roles.map(role => ({ value: role, label: role }))}
                                  onChange={handleRoleSelectChange}
                                  theme={layoutmode === 'dark' ? selectThemeDark : selectThemeLight}
                                />
                                {validation.touched.roles && validation.errors.roles ? (
                                  <div className="text-danger">{validation.errors.roles}</div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Groups</label>
                                <Select
                                  isMulti
                                  options={groups?.map(function (value) {
                                    return { label: value.groupname, value: value._id };
                                  })}
                                  value={validation.values.groups.map(group => ({ value: group, label: groups.find(g => g._id === group)?.groupname }))}
                                  onChange={handleGroupSelectChange} // Assuming you have a separate handler for group selection
                                  getOptionLabel={(option) => option.label} // Specify the label property to display in the dropdown
                                  getOptionValue={(option) => option.value}
                                  theme={layoutmode === 'dark' ? selectThemeDark : selectThemeLight}
                                />
                                {validation.touched.roles && validation.errors.roles ? (
                                  <div className="text-danger">{validation.errors.roles}</div>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Status</label>
                                <Input type="select" name="status" className="form-select" multiple={false}
                                  onChange={(e) => { e.preventDefault(); validation.setFieldValue("active", e.target.value === "true"); }}
                                  value={String(validation?.values?.active) === 'true'}
                                ><option value={true}>Active</option>
                                  <option value={false}>Inactive</option>
                                </Input>
                              </div>
                            </Row>
                            <Row>
                              <Col>
                                {!isEdit && <div className="text-start">
                                  <Button
                                    name="form-reset"
                                    className="btn btn-danger"
                                    onClick={validation.resetForm}
                                  >
                                    Reset
                                  </Button>
                                </div>}
                              </Col>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default UserList;
