import React, { useState, useEffect } from 'react';
import StickyNotes from '../StickyNotes/StickyNotes';

const CarouselPage = () => {
    const [notesText, setNotesText] = useState('');

    useEffect(() => {
        const fetchNotesText = async () => {
            try {
                const response = await fetch('/NotesText.txt'); // Assuming the text file is in the public folder
                const text = await response.text();
                setNotesText(text);
            } catch (error) {
                console.error('Failed to fetch notes text:', error);
            }
        };

        fetchNotesText();
    }, []);

    return (
        <React.Fragment>
            <div className="col-xxl-9 col-lg-8 col-md-7">
                <div className="auth-bg pt-md-5 p-4 d-flex">
                    <div className="bg-overlay bg-primary">
                        <StickyNotes text={notesText} />
                    </div>
                    <ul className="bg-bubbles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CarouselPage;
