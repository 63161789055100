import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link } from "react-router-dom"

import TableContainer from "../../components/Common/TableContainer"

import { toast } from 'react-toastify'

import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

import { isEmpty } from "lodash";

import {
  Title,
  Tabletext,
  DateTime
} from "../../components/Common/Tablecols";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getProjects,
  getLogs as onGetLogs,
  clearNotification
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux";
import LogTableContainer from "../../components/Common/LogTableContainer"

const ProjectLogs = props => {

  //meta title
  document.title = "Project List | VAIKins ";

  const dispatch = useDispatch();

  const { logs, projects, error, message } = useSelector((state) => ({
    logs: state.log?.logs,
    projects: state.project?.projects,
    error: state.project?.error,
    message: state.project?.message,
  }));

  const [isLoaded, setIsloaded] = useState(false)

  const toggleToast = () => {
    if (!isEmpty(message)) {
      toast.success(message); validation.resetForm(); setModal(false);
    }
    else if (error?.response?.data)
      toast.error(error.response.data.message)
    else if (error?.message)
      toast.error(error.message)
    dispatch(clearNotification())
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date & Time",
        accessor: 'timestamp',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <DateTime className="fw-medium" {...cellProps} />
          );
        },
      },
      {
        Header: "Project Title",
        accessor: "project.title",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Title className="fw-medium" {...cellProps} />
          );
        },
      },
      {
        Header: "Username",
        accessor: "username",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Tabletext {...cellProps} />;
        },
      },
      {
        Header: "Event",
        accessor: "event",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Tabletext {...cellProps} />;
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(getProjects());
    dispatch(onGetLogs());
    
    setIsloaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded && (!isEmpty(message) || (error?.message || error?.response))) toggleToast();
    if (!isEmpty(message)) setIsEdit(false);
  }, [message, error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Logs" breadcrumbItem="Project Logs" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <LogTableContainer
                        columns={columns}
                        data={logs}
                        isGlobalFilter={true}
                        customPageSize={10}
                        projects={projects}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProjectLogs;
