import { call, put, takeEvery } from "redux-saga/effects"

import { 
  GET_GROUPS, 
  GET_GROUP_DETAIL, 
  ADD_NEW_GROUP, 
  DELETE_GROUP, 
  UPDATE_GROUP 
} from "./actionTypes"
import {
  getGroupsSuccess,
  getGroupsFail,
  getGroupDetailSuccess,
  getGroupDetailFail,
  addGroupFail,
  addGroupSuccess,
  updateGroupSuccess,
  updateGroupFail,
  deleteGroupSuccess,
  deleteGroupFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getGroups,
  getGroupDetail,
  addNewGroup, 
  updateGroup, 
  deleteGroup,
} from "../../helpers/backend_helper"

function* fetchGroups() {
  try {
    const response = yield call(getGroups)
    yield put(getGroupsSuccess(response))
  } catch (error) {
    yield put(getGroupsFail(error))
  }
}

function* fetchGroupDetail({ groupId }) {
  try {
    const response = yield call(getGroupDetail, groupId)
    yield put(getGroupDetailSuccess(response))
  } catch (error) {
    yield put(getGroupDetailFail(error))
  }
}

function* onUpdateGroup({ payload: group }) {
  try {
    const response = yield call(updateGroup, group)
    yield put(updateGroupSuccess(response))
  } catch (error) {
    // console.log(error)
    yield put(updateGroupFail(error))
  }
}

function* onDeleteGroup({ payload: group }) {
  try {
    // console.log(group)
    const response = yield call(deleteGroup, group)
    yield put(deleteGroupSuccess(response))
  } catch (error) {
    yield put(deleteGroupFail(error))
  }
}

function* onAddNewGroup({ payload: group }) {

  try {
    const response = yield call(addNewGroup, group)
    yield put(addGroupSuccess(response))
  } catch (error) {
    yield put(addGroupFail(error))
  }
}

function* groupsaga() {
  yield takeEvery(GET_GROUPS, fetchGroups)
  yield takeEvery(GET_GROUP_DETAIL, fetchGroupDetail)
  yield takeEvery(ADD_NEW_GROUP, onAddNewGroup)
  yield takeEvery(UPDATE_GROUP, onUpdateGroup)
  yield takeEvery(DELETE_GROUP, onDeleteGroup)
}

export default groupsaga
