import {
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_USER_NOTIFICATION,
  UPDATE_PWD_SUCCESS,
  UPDATE_PWD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userDetail: {},
  error: {},
  message: "",
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_USER_SUCCESS:

      return {
        ...state,
        message: action.payload.message,
        users: [action.payload.user, ...state.users],
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        users: state.users.map(user =>
          user._id === action.payload.user._id
            ? { user, ...action.payload.user }
            : user
        ),
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PWD_SUCCESS:
      return {
        ...state,
        pwdMessage: action.payload.message
      }

    case UPDATE_PWD_FAIL:
      return {
        ...state,
        pwdError: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        users: state.users.filter(
          user => !(action.payload.message.includes(user.id))
        ),
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CLEAR_USER_NOTIFICATION:
      return {
        ...state,
        message: null,
        error: null,
      }
    default:
      return state
  }
}

export default Users
