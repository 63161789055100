import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_DETAIL,
  GET_USER_DETAIL_FAIL,
  GET_USER_DETAIL_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_USER_NOTIFICATION,
  UPDATE_PWD,
  UPDATE_PWD_SUCCESS,
  UPDATE_PWD_FAIL,
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const addNewUser = users => ({
  type: ADD_NEW_USER,
  payload: users,
})

export const addUserSuccess = users => ({
  type: ADD_USER_SUCCESS,
  payload: users,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const getUserDetail = userId => ({
  type: GET_USER_DETAIL,
  userId,
})

export const getUserDetailSuccess = users => ({
  type: GET_USER_DETAIL_SUCCESS,
  payload: users,
})

export const getUserDetailFail = error => ({
  type: GET_USER_DETAIL_FAIL,
  payload: error,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})
export const updatePassword = pwd => {
  return {
    type: UPDATE_PWD,
    payload: { pwd },
  }
}

export const updatePasswordSuccess = message => {
  return {
    type: UPDATE_PWD_SUCCESS,
    payload: message,
  }
}
export const updatePasswordFail = error => {
  return {
    type: UPDATE_PWD_FAIL,
    payload: error,
  }
}
export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})

export const clearUserNotification = message => ({
  type: CLEAR_USER_NOTIFICATION,
  payload: null,
})