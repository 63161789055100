import { call, put, takeEvery } from "redux-saga/effects"

// Log Redux States
import {
  GET_LOGS,
} from "./actionTypes"
import {
  getLogsSuccess,
  getLogsFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getLogs,
} from "../../helpers/backend_helper"

function* fetchLogs() {
  try {
    const response = yield call(getLogs)
    yield put(getLogsSuccess(response))
  } catch (error) {
    yield put(getLogsFail(error))
  }
}
// function* onAddNewLog({ payload: log }) {

//   try {
//     const response = yield call(addNewLog, log)
//     yield put(addLogSuccess(response))
//   } catch (error) {
//     yield put(addLogFail(error))
//   }
// }

function* logsaga() {
  yield takeEvery(GET_LOGS, fetchLogs)
  // yield takeEvery(ADD_NEW_LOG, onAddNewLog)
}

export default logsaga
