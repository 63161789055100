export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const GOOGLE_LOGIN_USER = "GOOGLE_LOGIN_USER"
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const REFRESH_TOKEN = "REFRESH_TOKENS"
export const REFRESH_SUCCESS = "REFRESH_SUCCESS"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"
export const CLEAR_AUTH_NOTIFICATION ="CLEAR_AUTH_NOTIFICATION"
