import PropTypes from 'prop-types';
import React from "react";
import { useEffect } from 'react';

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Import Routes all
import { userRoutes, authRoutes, adminRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

// Import the logoutUser action creator function
import { logoutUser } from "./store/actions";

const App = props => {
  const {layoutmode} = useSelector((state)=>({
    layoutmode:state.Layout?.layoutMode
  }))
  // const dispatch = useDispatch(); // get the dispatch function from the Redux store

  // useEffect(() => {

  //   window.onbeforeunload = function () {
  //     const rememberMe = localStorage.getItem('rememberMe');
  //     if (rememberMe === null || rememberMe === "false") {
  //       dispatch(logoutUser(props.history));
  //       // // Clear localStorage
  //       // localStorage.clear();
  //       // Clear cookies
  //       document.cookie.split(";").forEach(function (c) {
  //         document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  //       })
  //     }
  //   }
  // },[dispatch, props.history]);

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={route.path}
              isAuthProtected={false}
              exact
            />
          ))}
          {adminRoutes.map((route) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={route.path}
              isAuthProtected={true}
              isAdminRoutes={true}
              exact
            />
          ))}
          {userRoutes.map((route) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={route.path}
              isAuthProtected={true}
              exact
            />
          ))}


        </Switch>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={layoutmode}
      />
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)