import React from 'react';
import './StickyNotes.css';

const StickyNotes = ({ text }) => {
  return (
    <div className="sticky-notes-container">
      <div className="sticky-notes card">
        <div className="sticky-note">
          <div className="sticky-note-content">{text}</div>
        </div>
      </div>
    </div>
  );
};

export default StickyNotes;
