import { del, get, patch, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}
// Login Method
const postLogin = data => post(url.POST_LOGIN, data)
// export const jwtRefresh = data => post(url.JWT_REFRESH, data)
const postLogout = () => post(url.POST_LOGOUT)

// get projects
const getProjects = () => get(url.GET_PROJECTS)

// get project details
const getProjectDetail = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

const addNewProject = project => post(url.ADD_NEW_PROJECT, project)
const updateProject = project => patch(url.UPDATE_PROJECT, project)
const deleteProject = project => del(url.DELETE_PROJECT, project)

const deployProject = project => post(url.DEPLOY_TEST, project)
// export const deployProject = project => post(url.DEPLOY_TEST, project)
// export const deployProject = project => post(url.DEPLOY_TEST, project)

// get users
const getUsers = () => get(url.GET_USERS)

// get user details
const getUserDetail = id =>
  get(`${url.GET_USER_DETAIL}/${id}`, { params: { id } })

const addNewUser = user => post(url.ADD_NEW_USER, user)
const updateUser = user => patch(url.UPDATE_USER, user)
const updatePwd = pwd => patch(url.UPDATE_PWD, pwd)
const deleteUser = user => del(url.DELETE_USER, user)

// get groups
const getGroups = () => get(url.GET_GROUPS)

// get group details
const getGroupDetail = id =>
get(`${url.GET_GROUP_DETAIL}/${id}`, { params: { id } })

const addNewGroup = group => post(url.ADD_NEW_GROUP, group)
const updateGroup = group => patch(url.UPDATE_GROUP, group)
const deleteGroup = group => del(url.DELETE_GROUP, group)

const getLogs = () => get(url.GET_LOGS)
export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  postLogout,
  getProjects,
  getProjectDetail,
  addNewProject,
  updateProject,
  deleteProject,
  deployProject,
  getUsers,
  getUserDetail,
  addNewUser,
  updateUser,
  updatePwd,
  deleteUser,
  getGroups,
  getGroupDetail,
  addNewGroup,
  updateGroup,
  deleteGroup,
  getLogs
}
