//Real Login
export const POST_LOGIN = "/auth"
// export const JWT_REFRESH = "/auth/refresh"
export const POST_LOGOUT = "/auth/logout"
//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/projects"
export const ADD_NEW_PROJECT = "/projects"
export const UPDATE_PROJECT = "/projects"
export const DELETE_PROJECT = "/projects"
//DEPLOYMENT
export const DEPLOY_TEST = "/deploy/test"
export const DEPLOY_STAGE = "/deploy/stage"
export const DEPLOY_LIVE = "/deploy/live"

//Users
export const GET_USERS = "/users"
export const GET_USER_DETAIL = "/users"
export const ADD_NEW_USER = "/users"
export const UPDATE_USER = "/users"
export const DELETE_USER = "/users"
export const UPDATE_PWD = "/users/password"

//Groups
export const GET_GROUPS = "/groups"
export const GET_GROUP_DETAIL = "/groups"
export const ADD_NEW_GROUP = "/groups"
export const UPDATE_GROUP = "/groups"
export const DELETE_GROUP = "/groups"

export const GET_LOGS = "/logs/project"