import {
  GET_PROJECTS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL,
  GET_PROJECT_DETAIL_FAIL,
  GET_PROJECT_DETAIL_SUCCESS,
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  CLEAR_NOTIFICATION,
  DEPLOY_PROJECT,
  DEPLOY_PROJECT_FAIL,
  DEPLOY_PROJECT_SUCCESS
} from "./actionTypes"

export const getProjects = () => ({
  type: GET_PROJECTS,
})

export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})

export const getProjectsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
})

export const addNewProject = projects => ({
  type: ADD_NEW_PROJECT,
  payload: projects,
})

export const addProjectSuccess = projects => ({
  type: ADD_PROJECT_SUCCESS,
  payload: projects,
})

export const addProjectFail = error => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
})

export const getProjectDetail = projectId => ({
  type: GET_PROJECT_DETAIL,
  projectId,
})

export const getProjectDetailSuccess = projects => ({
  type: GET_PROJECT_DETAIL_SUCCESS,
  payload: projects,
})

export const getProjectDetailFail = error => ({
  type: GET_PROJECT_DETAIL_FAIL,
  payload: error,
})

export const updateProject = projects => ({
  type: UPDATE_PROJECT,
  payload: projects,
})

export const updateProjectSuccess = projects => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: projects,
})

export const updateProjectFail = error => ({
  type: UPDATE_PROJECT_FAIL,
  payload: error,
})

export const deleteProject = project => ({
  type: DELETE_PROJECT,
  payload: project,
})

export const deleteProjectSuccess = project => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: project,
})

export const deleteProjectFail = error => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
})

export const deployProject = project => ({
  type: DEPLOY_PROJECT,
  payload: project,
})

export const deployProjectSuccess = project => ({
  type: DEPLOY_PROJECT_SUCCESS,
  payload: project,
})

export const deployProjectFail = error => ({
  type: DEPLOY_PROJECT_FAIL,
  payload: error,
})

export const clearNotification = message => ({
  type: CLEAR_NOTIFICATION,
  payload: null,
})