/* PROJECTS */
export const GET_PROJECTS = "GET_PROJECTS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL"

export const GET_PROJECT_DETAIL = "GET_PROJECT_DETAIL"
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS"
export const GET_PROJECT_DETAIL_FAIL = "GET_PROJECT_DETAIL_FAIL"

/**
 * add project
 */
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT"
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS"
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL"

/**
 * Edit project
 */
export const UPDATE_PROJECT = "UPDATE_PROJECT"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL"

/**
 * Delete project
 */
export const DELETE_PROJECT = "DELETE_PROJECT"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL"

/**
 * DEPLOY project
 */
export const DEPLOY_PROJECT = "DEPLOY_PROJECT"
export const DEPLOY_PROJECT_SUCCESS = "DEPLOY_PROJECT_SUCCESS"
export const DEPLOY_PROJECT_FAIL = "DEPLOY_PROJECT_FAIL"


export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION"