import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Row, Col, Button, Input } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import { Table } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Col sm={4}>
      <div className="search-box me-2 d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <input
              onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              placeholder={`${count} records...`}
              value={value || ""}
            />
          </label>
          <i className="mdi mdi-magnify search-icon"></i>
        </div>
      </div>
    </Col>
  );
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddOptions,
  isAddUserList,
  isAddProjectList,
  isAddDefaultProject,
  handleOrderClicks,
  handleUserClick,
  handleProjectClick,
  handleDefaultClick,
  handleEditClick,
  handleDelete,
  handleDetailProject,
  handleGroupClick,
  isAddGroupList,
  customPageSize,
  className,
  isAdmin,
  id
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: customPageSize },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " mdi mdi-arrow-up-thin" : " mdi mdi-arrow-down-thin") : "\t";
  };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };
  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={2}>
          <select
            className="form-select mb-2"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        {isAddUserList && isAdmin && (
          <Col sm="6">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddProjectList && isAdmin && (
          <Col sm="6">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleProjectClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create Project
              </Button>
              {isAddDefaultProject && <Button
                type="button"
                color="success"
                className="btn mb-2 me-2"
                onClick={handleDefaultClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Default Project
              </Button>}
            </div>
          </Col>
        )}
        {isAddGroupList && isAdmin && (
          <Col sm="6">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleGroupClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                New Group
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-rep-plugin">
        <div className="table-responsive " id={id}>
          <Table bordered="true" hover="true" {...getTableProps()} className={"table table-striped table-bordered " + className}>
            <thead className="table-light fw-medium text-dark table-nowrap">
              {headerGroups.map(headerGroup => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th key={column.id}>
                      <div className={`mb-2 ${generateSortingIndicator(column)}`}  {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                      </div>
                      <Filter column={column} />
                    </th>
                  ))}
                  {((handleEditClick || handleDelete) && isAdmin) ? (<th><div className="mb-2 text-center" title="Toggle SortBy" id="action" style={{ cursor: "pointer" }}>Action</div><div style={{ marginTop: "5px" }}></div></th>) : null}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}{(handleEditClick || handleDelete) && isAdmin && <td><div className="d-flex flex-wrap gap-2">
                        {handleEditClick ?
                          <Button
                            title="Edit"
                            type="button"
                            color="primary"
                            className="mdi mdi-pencil border-warning btn-soft-warning btn-rounded"
                            onClick={() => { handleEditClick(row.original) }}
                          ></Button>
                          : null}{handleDelete && <Button
                            title="Delete"
                            type="button"
                            className="mdi mdi-delete-forever border-danger btn-soft-danger btn-rounded"
                            onClick={() => { handleDelete(row.original) }}
                          ></Button>}
                      </div></td>}
                    </tr>

                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageOptions.length > 0 ? pageIndex + 1 : pageIndex} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;