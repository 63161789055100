import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { Row, Col, Container, Form, Label, Input, FormFeedback, } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux"
import { toast } from 'react-toastify';
import { withRouter, Link } from "react-router-dom"

import { isEmpty } from "lodash";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import {
  loginUser,
  clearAuthNotification,
  googleloginUser
} from "../../store/actions"

// import images
import logo from "../../assets/images/logo3d.svg"

//Import config
import CarouselPage from "./CarouselPage"

const Login = props => {

  const dispatch = useDispatch()

  const { error, message } = useSelector(state => ({
    error: state.Login.error?.message ? state.Login.error?.message : state.Login.error,
    message: state.Login?.message
  }))

  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    }
  });
  const toggleToast = () => {
    if (!isEmpty(error)) {
      toast.error(error)
      dispatch(clearAuthNotification())
    }
    else if (!isEmpty(message)) {
      toast.success(message)
      dispatch(clearAuthNotification())
    }

  };

  useEffect(() => {
    toggleToast();
  }, [error])

  useEffect(() => {
    const rememberMeValue = localStorage.getItem("rememberMe");
    if (rememberMeValue) {
      setRememberMe(JSON.parse(rememberMeValue));
    } else {
      setRememberMe(false); // Set initial value to false
    }
  }, []);

  const handleRememberMeChange = (event) => {
    const { checked } = event.target;
    console.log(checked); // Check the value of checked
    setRememberMe(checked);
    localStorage.setItem("rememberMe", JSON.stringify(checked));
  };

  document.title = "Login | VAIKins ";

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="28" /> <span className="logo-txt">VAIKins</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back !</h5>
                        <p className="text-muted mt-2">Sign in to continue to VAIKins.</p>
                        <div>
                        </div>
                      </div>
                      <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Username</Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder="Enter username"
                            type="username"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username && validation.errors.username ? true : false
                            }
                            autoComplete="username"
                          />
                          {validation.touched.username && validation.errors.username ? (
                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <div className="auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={showPassword?"input":"password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                            autoComplete="current-password"
                            className="auth-pass-input"
                          />
                          <span className="btn" onClick={e =>{e.preventDefault();setShowPassword(!showPassword)}}>
                            <i className="mdi mdi-eye-outline"></i>
                          </span>
                          </div>
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}

                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="remember-check"
                                checked={localStorage.getItem("rememberMe") === "true"}
                                onChange={handleRememberMeChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="remember-check"
                              >
                                Remember me
                              </label>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">© {new Date().getFullYear()} VAIKins</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}