import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, REFRESH_TOKEN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess, refreshTokenSuccess } from "./actions"
import setAuthToken from "../../../utils/setAuthToken"
import {
  postLogin,
  postLogout,
  jwtRefresh,
} from "../../../helpers/backend_helper"

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "real") {
      const response = yield call(postLogin, {
        username: user.username,
        password: user.password,
      })
      // console.log(response)
      localStorage.setItem("authorization", JSON.stringify(response))
      setAuthToken(response.accessToken)
      yield put(loginSuccess(response))
      history.push("/dashboard")
    }
  } catch (error) {
    yield put(apiError(error))
  }
}
function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(postLogout)
    localStorage.removeItem("authorization")
    localStorage.removeItem("rememberMe")
    setAuthToken("")
    history.push("/login")
    yield put(logoutUserSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

// function* jwtRefreshToken(){
//   try {
//     const response = yield call(jwtRefresh)
//     localStorage.setItem("authorization", JSON.stringify(response))
//     setAuthToken(response?.accessToken)
//     yield put(refreshTokenSuccess(response))
//   } catch (error) {
//     yield put(apiError(error))
//   }
// }

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  // yield takeEvery(REFRESH_TOKEN, jwtRefreshToken)
}

export default authSaga
