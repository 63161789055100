import React, { useEffect, useState, useRef, useMemo } from "react"
import { del, get } from "../../helpers/api_helper"
import { toast } from 'react-toastify'

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";

import { isEmpty } from "lodash";

import {

} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux";

const ServerLogs = () => {

    //meta title
    document.title = "Server Logs | VAIKins ";

    const dispatch = useDispatch();

    const { error, message, layoutmode } = useSelector((state) => ({
        error: state.serverlog?.error,
        message: state.serverlog?.message,
        layoutmode: state.Layout?.layoutMode
    }));
    const [isLoaded, setIsloaded] = useState(false)
    const [activeTab, setActiveTab] = useState('user');
    const [userLogs, setUserLogs] = useState('');
    const [reqLogs, setReqLogs] = useState('');
    const [errLogs, setErrLogs] = useState('');
    const [mongoErrLogs, setMongoErrLogs] = useState('');
    const preRefs = useRef([]); // Create an array of refs for the <pre> elements

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const toggleToast = () => {
        if (!isEmpty(message)) {
            toast.success(message); validation.resetForm(); setModal(false);
        }
        else if (error?.response?.data)
            toast.error(error.response.data.message)
        else if (error?.message)
            toast.error(error.message)
        dispatch(onclearNotification())
    };

    // Function to scroll a specific <pre> element to the bottom
    const scrollToBottom = (tab) => {
        const preRef = preRefs.current[tab];
        if (preRef) {
            preRef.scrollTop = preRef.scrollHeight;
        }
    };

    useEffect(() => {
        Object.keys(preRefs.current).forEach((tab) => scrollToBottom(tab));
        setIsloaded(true);
    }, []);
    useEffect(() => {
        if (isLoaded && (!isEmpty(message) || (error?.message || error?.response))) toggleToast();
        if (!isEmpty(message)) setIsEdit(false);
    }, [message, error])
    const fetchLogs = async (tab) => {
        if (tab === 'user' && userLogs === '') {
            const res = await fetchLogsFromAPI(tab)
            setUserLogs(res);
        } else if (tab === 'req' && reqLogs === '') {
            const res = await fetchLogsFromAPI(tab)
            setReqLogs(res);
        } else if (tab === 'err' && errLogs === '') {
            const res = await fetchLogsFromAPI(tab)
            setErrLogs(res);
        } else if (tab === 'mongoErr' && mongoErrLogs === '') {
            const res = await fetchLogsFromAPI(tab)
            setMongoErrLogs(res);
        }
    };
    const refreshLog = async (tab) => {
        if (tab === 'user') {
            const res = await fetchLogsFromAPI(tab)
            setUserLogs(res);
        } else if (tab === 'req') {
            const res = await fetchLogsFromAPI(tab)
            setReqLogs(res);
        } else if (tab === 'err') {
            const res = await fetchLogsFromAPI(tab)
            setErrLogs(res);
        } else if (tab === 'mongoErr') {
            const res = await fetchLogsFromAPI(tab)
            setMongoErrLogs(res);
        }
        scrollToBottom(tab);
    };
    const fetchLogsFromAPI = async (logtype) => {
        try {
            const response = await get('/logs', { params: { logtype: logtype } });
            // Handle the response data
            return response;
        } catch (error) {
            // Handle any errors that occurred during the request
            toast.error(error);
        }
    }
    useEffect(() => {
        fetchLogs(activeTab); // Fetch logs when the component mounts or activeTab changes
    }, [activeTab]);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Logs" breadcrumbItem="Server Logs" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink className={activeTab === 'user' ? 'active' : ''} onClick={() => toggleTab('user')}>
                                                        User Logs
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={activeTab === 'req' ? 'active' : ''} onClick={() => toggleTab('req')}>
                                                        Request Logs
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={activeTab === 'err' ? 'active' : ''} onClick={() => toggleTab('err')}>
                                                        Error Logs
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={activeTab === 'mongoErr' ? 'active' : ''} onClick={() => toggleTab('mongoErr')}>
                                                        MongoDB Logs
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <Button className="btn float-end mdi mdi-refresh btn-info" onClick={() => refreshLog(activeTab)}>
                                                Refresh
                                            </Button>
                                        </div>
                                        <TabContent activeTab={activeTab} className="mt-1">
                                            <TabPane tabId="user">
                                                <pre
                                                    ref={(ref) => (preRefs.current['user'] = ref)}
                                                    style={{
                                                        overflowY: 'auto',
                                                        overflowX: 'hidden',
                                                        maxHeight: '70vh',
                                                        minHeight: '70vh',
                                                        backgroundColor: 'black',
                                                        color: 'white',
                                                        
                                                        fontFamily: 'Courier New, monospace',
                                                        fontSize: '12px',
                                                        lineHeight: '1.5',
                                                        whiteSpace: 'pre-wrap',
                                                        
                                                    }}
                                                    >{userLogs}</pre>
                                            </TabPane>
                                            <TabPane tabId="req">
                                                <pre
                                                    ref={(ref) => (preRefs.current['req'] = ref)}
                                                    style={{
                                                        overflowY: 'auto',
                                                        overflowX: 'hidden',
                                                        maxHeight: '70vh',
                                                        minHeight: '70vh',
                                                        backgroundColor: 'black',
                                                        color: 'white',
                                                        
                                                        fontFamily: 'Courier New, monospace',
                                                        fontSize: '12px',
                                                        lineHeight: '1.5',
                                                        whiteSpace: 'pre-wrap',
                                                        
                                                    }}
                                                    >{reqLogs}</pre>
                                            </TabPane>
                                            <TabPane tabId="err">
                                                <pre
                                                    ref={(ref) => (preRefs.current['err'] = ref)}
                                                    style={{
                                                        overflowY: 'auto',
                                                        overflowX: 'hidden',
                                                        maxHeight: '70vh',
                                                        minHeight: '70vh',
                                                        backgroundColor: 'black',
                                                        color: 'white',
                                                        
                                                        fontFamily: 'Courier New, monospace',
                                                        fontSize: '12px',
                                                        lineHeight: '1.5',
                                                        whiteSpace: 'pre-wrap',
                                                        
                                                    }}
                                                    >{errLogs}</pre>
                                            </TabPane>
                                            <TabPane tabId="mongoErr">
                                                <pre
                                                    ref={(ref) => (preRefs.current['mongoErr'] = ref)}
                                                    style={{
                                                        overflowY: 'auto',
                                                        overflowX: 'hidden',
                                                        maxHeight: '70vh',
                                                        minHeight: '70vh',
                                                        backgroundColor: 'black',
                                                        color: 'white',

                                                        fontFamily: 'Courier New, monospace',
                                                        fontSize: '12px',
                                                        lineHeight: '1.5',
                                                        whiteSpace: 'pre-wrap',

                                                    }}
                                                >{mongoErrLogs}</pre>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default ServerLogs;
