import React from "react";
import { Input } from "reactstrap";

const Title = (cell) => {
  return (
    <span className="text-dark fw-medium" to="#">{cell.value}</span>
  );
};

const Tabletext = (cell) => {
  return <span className="text-dark">{cell.value ? cell.value : ''}</span>
};

const TableStatus = (cell) => {
  return <span className="text-dark">{cell.value ? "Active" : 'Inactive'}</span>
}
const DateTime = (cell) => {
  return (
    <span className="text-dark">{cell.value ? new Date(cell.value).toLocaleString() : ''}</span>
  )
}

export {
  Title,
  Tabletext,
  DateTime,
  TableStatus
};