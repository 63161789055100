import {
  GET_GROUPS_FAIL,
  GET_GROUPS_SUCCESS,
  GET_GROUP_DETAIL_SUCCESS,
  GET_GROUP_DETAIL_FAIL,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAIL,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  CLEAR_GROUP_NOTIFICATION,
} from "./actionTypes"

const INIT_STATE = {
  groups: [],
  groupDetail: {},
  error: {},
  message: "",
}

const Groups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
      }

    case GET_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_GROUP_SUCCESS:

      return {
        ...state,
        message: action.payload.message,
        groups: [action.payload.group, ...state.groups],
      }

    case ADD_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        groupDetail: action.payload,
      }

    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        groups: state.groups.map(group =>
          group._id === action.payload.group._id
            ? { group, ...action.payload.group }
            : group
        ),
      }

    case UPDATE_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        groups: state.groups.filter(
          group => !(action.payload.message.includes(group._id))
        ),
      }

    case DELETE_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_GROUP_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CLEAR_GROUP_NOTIFICATION:
      return {
        ...state,
        message: null,
        error: null,
      }
    default:
      return state
  }
}

export default Groups
