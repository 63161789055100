import {
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECT_DETAIL_SUCCESS,
  GET_PROJECT_DETAIL_FAIL,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  DEPLOY_PROJECT_SUCCESS,
  DEPLOY_PROJECT_FAIL,
  CLEAR_NOTIFICATION,
  //TODO assign user with each BCast - need to be done when OAuth2 is available
  // GET_PROJECT_PROFILE_SUCCESS,
  // GET_PROJECT_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  projects: [],
  projectDetail: {},
  error: {},
  message: "",
}

const Projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      }

    case GET_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        projects: [...state.projects, action.payload.project],
      }

    case ADD_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        projectDetail: action.payload,
      }

    case GET_PROJECT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        projects: state.projects.map(project =>
          project._id === action.payload.project._id
            ? { project, ...action.payload.project }
            : project
        ),
      }

    case UPDATE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        projects: state.projects.filter(
          project => !(action.payload.message.includes(project._id))
        ),
      }

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DEPLOY_PROJECT_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      }

    case DEPLOY_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CLEAR_NOTIFICATION:
      return {
        ...state,
        message: null,
        error: null,
      }

    default:
      return state
  }
}

export default Projects
