import React, { useState, useEffect } from "react";
import jwt from 'jwt-decode'
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Modal,
  ModalBody,
  Input,
  Button,
  FormFeedback,
  Form,
  ModalHeader,
} from "reactstrap";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { clearUserNotification, updatePassword } from "../../../store/actions";
import { isEmpty } from "lodash";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [isPwdModal, setIsPwdModal] = useState(false)
  const [username, setusername] = useState("Admin");
  const { loading, message, error } = useSelector(state => ({
    loading: state.Login.loading,
    message: state.user.pwdMessage?state.user.pwdMessage:"",
    error: state.user.pwdError?state.user.pwdError:"",
  }
  ))
  const dispatch = useDispatch()
  const togglePwd = () => {
    setIsPwdModal(!isPwdModal)
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      cur_pwd: '',
      new_pwd: '',
      new_pwd_confirm: ''
    },
    validationSchema: Yup.object({
      cur_pwd: Yup.string().required("Please enter your current password"),
      new_pwd: Yup.string()
        .required("Please enter a new password")
        .min(8, "Password must be at least 8 characters long"),
      new_pwd_confirm: Yup.string()
        .oneOf([Yup.ref('new_pwd'), null], "Passwords must match")
        .required("Please confirm your new password"),
    }),
    onSubmit: (values) => {
      dispatch(updatePassword(values));
    }
  });
  const toggleToast = () => {
    if (!isEmpty(message)) {
      toast.success(message); validation.resetForm(); setIsPwdModal(false);
    }
    else if (error?.response?.data)
      toast.error(error.response.data.error)
    else if (error?.message)
      toast.error(error.message)
    dispatch(clearUserNotification())
  };

  useEffect(() => {
    if ((!isEmpty(message) || (error?.message || error?.response))) toggleToast();
    if (!isEmpty(message));
  }, [message, error])

  useEffect(() => {
    try {
      if (process.env.REACT_APP_DEFAULTAUTH === "real") {
        const obj = jwt(localStorage.getItem("authorization"));
        setusername(obj.UserInfo.username);
      }
    } catch (err) { console.log(err) }
  }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <i className="mdi mdi-account d-xl-inline-block" />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to='#' onClick={e => { e.preventDefault(); togglePwd() }} className="dropdown-item">
            <i className="mdi mdi-lock font-size-16 align-middle me-1 text-danger" />
            <span>{"Change Password"}</span>
          </Link>
          <Link to="/logout" className="dropdown-item">
            <i className="mdi mdi-logout font-size-16 align-middle me-1 text-danger" />
            <span>{"Logout"}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <Modal toggle={togglePwd} isOpen={isPwdModal} backdrop={'static'}>
        <ModalHeader toggle={togglePwd} tag="h4">
          Update Password
        </ModalHeader>
        <ModalBody>
          <Form
            className="custom-form pt-2"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <Label className="form-label">Current Password</Label>
              <Input
                name="cur_pwd"
                value={validation.values.cur_pwd || ""}
                type={"password"}
                placeholder="Enter Password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.cur_pwd && validation.errors.cur_pwd ? true : false
                }
              />
              {validation.touched.cur_pwd && validation.errors.cur_pwd ? (
                <FormFeedback type="invalid">{validation.errors.cur_pwd}</FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label">New Password</Label>
              <Input
                name="new_pwd"
                value={validation.values.new_pwd || ""}
                type={"password"}
                placeholder="Enter Password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.new_pwd && validation.errors.new_pwd ? true : false
                }
              />
              {validation.touched.new_pwd && validation.errors.new_pwd ? (
                <FormFeedback type="invalid">{validation.errors.new_pwd}</FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label">Confirm Password</Label>
              <Input
                name="new_pwd_confirm"
                value={validation.values.new_pwd_confirm || ""}
                type={"password"}
                placeholder="Enter Password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.new_pwd_confirm && validation.errors.new_pwd_confirm ? true : false
                }
              />
              {validation.touched.new_pwd_confirm && validation.errors.new_pwd_confirm ? (
                <FormFeedback type="invalid">{validation.errors.new_pwd_confirm}</FormFeedback>
              ) : null}
            </div>
            <div className="mt-3 d-grid">
              <button
                className="btn btn-primary btn-block"
                type="submit"
              >
                Submit
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state;
  return { error, success };
};

export default
  connect(mapStatetoProps, {})(ProfileMenu);