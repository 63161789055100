export const selectThemeLight = (theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary: '###',  
    primary25: '#bcbcbc',
    primary50: '#dcdcdc',
    primary75: '#efefef',
    neutral0: '#f0f0f0', 
    neutral5: '#a2a2aa', 
    neutral10: '#a2a2aa',
    neutral20: '#a2a2aa',
    neutral30: '#4c4c54',
    neutral40: '#000',
    neutral50: '#70707f',
    neutral60: '#a2a2aa',
    neutral70: '#bbbbbb',
    neutral80: '#000',
    neutral90: '#999999',
    danger:'#2c302d',
    // dangerLight:'#585c58',
  }
});
export const selectThemeDark = (theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary: '#2c302d',
    primary25: '#1d201e',
    primary50: '#0e100f',
    primary75: '#070807',
    neutral0: '#262a2f',
    neutral5: '#222222',
    neutral10: '#14171a',
    neutral20: '#2c302d',
    neutral30: '#bbbbbb',
    neutral40: '#dddddd',
    neutral50: '#eeeeee',
    neutral60: '#70707f',
    neutral70: '#ffffff',
    neutral80: '#bbbbbb',
    neutral90: '#999999',
    danger:'#000',
    dangerLight:'#ff7f7f',
  }
});
